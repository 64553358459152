import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

class Multiselect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: this.props.selected
    };
  }

  // callback is used if this is a react subcomponent and
  // submission is handled by parent component
  handleChange = (selectedOptions) => {
    this.setState({ selectedOptions });
    if (this.props.callback) {
      this.props.callback(selectedOptions);
    }
  };

  render() {
    return (
      <Select
        name={this.props.element_name}
        isMulti
        value={this.state.selectedOptions}
        onChange={this.handleChange}
        options={this.props.options}
        classNamePrefix={this.props.class_prefix}
      />
    );
  }
}

Multiselect.propTypes = {
  callback: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object),
  element_name: PropTypes.string,
  class_prefix: PropTypes.string
};

Multiselect.defaultProps = {
  options: null,
  selected: null,
  callback: null,
  element_name: null,
  class_prefix: null
};

export default Multiselect;
