import $ from "jquery";

$(() => {
  const sbc = document.getElementById("sidebarCollapse");
  const sb = document.getElementById("sidebar");
  if (sbc) {
    sbc.addEventListener("click", () => {
      if (sb.classList.contains("collapse")) {
        $("#sidebar").attr("class", "");
        $(".fa-angle-double-left").attr("class", "fas fa-angle-double-right fa-2x");
      } else {
        $("#sidebar").attr("class", "collapse");
        $(".fa-angle-double-right").attr("class", "fas fa-angle-double-left fa-2x");
      }
    });
  }

  $(".remove_from_cart").each((i, item) => {
    item.addEventListener("click", () => {
      fetch("/api/v1/remove_from_cart", {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({ id: item.id }),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then((response) => {
          if (response.status === 200) {
            response.json()
              .then((json) => {
                // remove the div that represents the cart item
                item.parentNode.parentNode.remove();
                const cartQuantity = json.cart_quantity;
                $("#cart_toggle_count").html(cartQuantity);
                const price = (cartQuantity * window.unit_price + window.transmittal_fee).toFixed(2);
                $("#final_price").html(`$${price}`);
                // show or hide fee div based on cart quantity
                if (cartQuantity === 0) {
                  // Collape sidebar if cart is empty, remove cart total div and checkout button.
                  $("#checkout").remove();
                  $("#cart_total").remove();
                  $("#sidebar").attr("class", "collapse");
                  $(".fa-angle-double-right").attr("class", "fas fa-angle-double-left fa-2x");
                }
                location.reload();
              });
          // create error if it doesn't already exist
          } else if (!document.getElementById(`cart_error_${item.id}`)) {
            const span = document.createElement("SPAN");
            span.id = `cart_error_${item.id}`;
            span.innerText = "Unable to remove strain, please refresh the page";
            span.style.cssText = "margin-bottom: 4px; font-size: 12px; color: red";
            item.appendChild(span);
          }
        })
        .catch((err) => console.error(err));
    });
  });
});
