/* eslint-disable */
import $ from "jquery";

function changeTextFields(ad, elementId) {
  for (const key in ad) {
    if (elementId === "csi_picker") {
      const inputID = "#csi_" + key;
      $(inputID).val(ad[key]);
      $(inputID).prop("readonly", true);
    } else {
      const inputID = "#pi_" + key;
      $(inputID).val(ad[key]);
      $(inputID).prop("readonly", true);
    }
  }
}

function syncFields() {
  $('#pi_first_name').val($('#csi_first_name').val());
  $('#pi_last_name').val($('#csi_last_name').val());
  $('#pi_institution').val($('#csi_institution').val());
  $('#pi_mailing_address').val($('#csi_mailing_address').val());
  $('#pi_city').val($('#csi_city').val());
  $('#pi_state').val($('#csi_state').val());
  $('#pi_zip').val($('#csi_zip').val());
  $('#pi_region').val($('#csi_region').val());
  $('#pi_country').val($('#csi_country').val());
  $('#pi_phone').val($('#csi_phone').val());
  $('#pi_email').val($('#csi_email').val());
}

$(".sync-fill").keyup(function () {
  const checked = document.getElementById('same_address').checked;
  if (checked) {
    syncFields();
  }
});

$("#same_address").click(function () {
  syncFields();
});

$("#undo_btn").click(function () {
  $('#new_order').trigger("reset");
});

$( ".address_picker" ).change(function() {
  let address_details = JSON.parse(document.getElementById(this.value).dataset.attrs);
  changeTextFields(address_details, this.id);
  const editUrl = "/addresses/" + this.value + "/edit"
  if (this.id === "csi_picker") {
    $("#csi_edit_links").css("visibility", "visible");
    $("#edit_csi_address").attr("href", editUrl);
    $("#csi_copy_chk").css("visibility", "hidden");
  } else {
    $("#pi_edit_links").css("visibility", "visible");
    $("#edit_pi_address").attr("href", editUrl);
  }
});
