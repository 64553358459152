import $ from "jquery";

$(() => {
  const button = document.getElementById("remove_pub_filter_btn");
  if (button) {
    button.addEventListener("click", () => {
      const hiddenField = $("#remove_pub");
      hiddenField.attr("value", "true");
      $("div.linked-from-pub").remove();
    });
  }
});
