// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require simple_form_autocomplete

import "core-js/stable";
import "regenerator-runtime/runtime";

import "./add_jquery";

import "bootstrap";
import "easy-autocomplete/dist/jquery.easy-autocomplete";
import "./addresses";
import "./drop_pub_filter";
import "./shopping_cart";
import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";

Rails.start();
ActiveStorage.start();

require("./channels");

// Support component names relative to this directory:
const componentRequireContext = require.context("./components", true);
const ReactRailsUJS = require("react_ujs");

ReactRailsUJS.useContext(componentRequireContext);
